import { Col, Container, Row } from "react-bootstrap";
import styles from "../YourReport/YourReport.module.css";
import Styles from "./Message.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { ImCross } from "react-icons/im";
import { FaCirclePlus } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import {
  blockUser,
  FireBaseChat,
  getBlockStatus,
  sendMessage,
  unblockUser,
  clearChatData,
  updateUserData,
  getUserById,
} from "../../../firebase/FireBaseChat";
import {
  ChatTimestampToDate,
  img_path,
  timestampToDate,
} from "../../../commonComponent/ExtraPlus";
import { BsThreeDotsVertical } from "react-icons/bs";
import icon from "../../../assets/Images/logo2.svg";
import ReactPlayer from "react-player";
import ImagePreviewModal from "./ImagePreviewModal";
import ConfirmationModal from "./ConfirmationModal";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { IoCheckmarkSharp } from "react-icons/io5";
import recordIcon from "../../../assets/Images/record.jpg";
import { FaCirclePlay } from "react-icons/fa6";
import { FaPauseCircle } from "react-icons/fa";
import moment from "moment";
import { database } from "../../../firebase/firebase";
import {
  ref,
  get,
  onValue,
  off,
  onChildAdded,
  update,
} from "firebase/database";
import SublyApi from "../../../helpers/Api";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";

function Chat() {
  const { currentUser, userToken } = useSelector((state) => state.user);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const historyData = location.state;
  const [loader, setLoader] = useState(false);
  const [chat, setChat] = useState([]);
  const [comment, setComment] = useState("");
  const [userData, setUserData] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [blockStatus, setBlockStatus] = useState("");
  const [blockCurrentStatus, setBlockCurrentStatus] = useState(0);
  const [blockModal, setBlockModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");
  const [userId, setUserId] = useState(null);

  const [successCount, setSuccessCount] = useState(0);
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioTimer, setAudioTimer] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);

  async function updateMessageNode(newChildKey, newValue) {
    const chatUserRef = ref(
      database,
      `chat_rooms/${id}/${currentUser.userID}/${newChildKey}`
    );
    const res = await update(chatUserRef, newValue);
  }

  async function UpdateFirebaseUser(userID, updObject) {
    updateUserData(userID, updObject);
  }

  useEffect(() => {
    const updateObject = {
      on_chat_userid: currentUser.userID + "-" + id,
    };
    UpdateFirebaseUser(id, updateObject);
  }, [id]);

  useEffect(() => {
    const chatHistoryRef = ref(
      database,
      `chat_history/${currentUser.userID}/${id}`
    );
    const unsubscribe1 = onValue(chatHistoryRef, async (snapshot) => {
      const userData = snapshot.val();

      if (userData) {
        await update(chatHistoryRef, {
          unread_count: "0",
          unreadCount: "0",
        });
      }
    });
    // Cleanup subscription on unmount
    return () => {
      unsubscribe1();
    };
  }, []);

  async function reduceChatCount() {
    const chatHistoryRef = ref(
      database,
      `chat_history/${currentUser.userID}/${id}`
    );
    const snapshotRec = await get(chatHistoryRef);
    const chatHistory = snapshotRec.val();
    const userUnredCount = await parseInt(Number(chatHistory?.unread_count));
    if (userUnredCount > 0) {
      const currentUserData = await getUserById(currentUser.userID);
      const chatCount = await parseInt(Number(currentUserData?.chat_count));
      const updateObject = {
        chat_count: chatCount > 0 ? chatCount - 1 : 0,
      };
      await updateUserData(currentUser.userID, updateObject);
    }
  }

  async function updateChatUserChatCount() {
    if (historyData) {
      if (historyData?.history?.unread_count > 0) {
        const currentUserData = await getUserById(currentUser.userID);
        const chatCount = await parseInt(Number(currentUserData?.chat_count));
        const updateObject = {
          chat_count: chatCount > 0 ? chatCount - 1 : 0,
        };
        await updateUserData(currentUser.userID, updateObject);
      }
    } else {
      reduceChatCount();
    }
  }

  useEffect(() => {
    updateChatUserChatCount();
  }, []);

  useEffect(() => {
    // when new message comes
    const dataRef1 = ref(database, `chat_rooms/${id}/${currentUser.userID}`);
    const unsubscribe1 = onChildAdded(dataRef1, async (snapshot) => {
      const newMsg = snapshot.val();
      await updateMessageNode(snapshot.key, { msg_read_tick: 2 });
    });
    // Cleanup subscription on unmount
    return () => {
      unsubscribe1();
    };
  }, []);

  useEffect(() => {
    switch (blockCurrentStatus) {
      case 1:
        setBlockStatus(
          `You have blocked @${userData.user_name}. Can't send any message.`
        );
        break;
      case 2:
        setBlockStatus("You have been blocked by this user");
        break;
      case 3:
        setBlockStatus("Both have blocked each other");
        break;
      default:
        setBlockStatus("");
        break;
    }
  }, [blockCurrentStatus]);

  useEffect(() => {
    setLoader(true);
    const setChatList = (messageList) => {
      const sortedMessages = messageList.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      setChat(sortedMessages);
      setLoader(false);
    };
    const unsubscribe = FireBaseChat(currentUser, setChatList, id, setUserData);

    return () => {
      unsubscribe();
    };
  }, [currentUser, id]);

  const ChatTextBrTageFormate = (text) => {
    if (!text) return "";
    return text.replace(/\r\n|\r|\n/g, "<br />");
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setSelectedImage(null);
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("receiver_id", id);
    const fileType = selectedImage?.type.startsWith("video")
      ? "Video"
      : "Image";
    requestData.append("type", fileType);
    requestData.append("image", fileType === "Image" ? 1 : 0);
    requestData.append("msg", comment ? comment : "Image");

    if (comment.trim() || selectedImage) {
      try {
        await sendMessage(
          currentUser,
          id,
          comment,
          selectedImage,
          setPreviewImage
        );
        // Clear the input fields after sending the message
        setComment("");
        setPreviewImage(null);
        setSelectedImage(null);
        setLoader(true);

        SublyApi.firebaseChatNotification(requestData, userToken).then(
          (response) => {
            setLoader(false);
            const msg = response && response.message;
            const errormsg = response && response.data && response.data.message;
            if (response && response.status_code === STATUS_CODES.SUCCESS) {
            } else if (
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "ACCOUNT_DISABLED") ||
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "SESSION_EXPIRED") ||
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "INVALID_TOKEN")
            ) {
              dispatch(userLogoutClear());
              navigate("/");
              Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
            } else {
              Toster(t(errormsg), "error");
            }
          }
        );
      } catch (error) {
        console.error("Error sending message: ", error);
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type.startsWith("video") ? "video" : "image";
    setPreviewImage({
      url: URL.createObjectURL(file),
      type: fileType,
    });
    setSelectedImage(file);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleImageClick = (url) => {
    setModalImageUrl(url);
    setShowModal(true);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageUrl(null);
    setBlockModal(false);
  };

  const handleShowModal = (actionType, id) => {
    setAction(actionType);
    setUserId(id);
    setBlockModal(true);
    setDropdownOpen(false);
  };

  const handleConfirmAction = async () => {
    try {
      if (action === "block") {
        await blockUser(currentUser.userID, userId, setSuccessCount);
      } else if (action === "unblock") {
        await unblockUser(currentUser.userID, userId);
      } else if (action === "delete") {
        // Add this condition
        await clearChatData(currentUser.userID, id);
      }
      setBlockModal(false);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    const checkBlockStatus = async () => {
      try {
        const status = await getBlockStatus(
          currentUser.userID,
          id,
          setBlockCurrentStatus
        );
      } catch (error) {
        console.error("Error fetching block status:", error);
      }
    };

    checkBlockStatus();
  }, [currentUser, id, successCount]);

  // Function to render read tick
  const renderReadTick = (msg_read_tick) => {
    if (msg_read_tick == 0) {
      return <IoCheckmarkSharp style={{ color: "rgb(225 220 220)" }} />; // Message sent but not read
    } else if (msg_read_tick == 1) {
      return <IoCheckmarkDoneSharp style={{ color: "rgb(225 220 220)" }} />; // Message read
    } else {
      return <IoCheckmarkDoneSharp style={{ color: "rgb(40 195 81)" }} />; // Message read
    }
  };

  const handlePlayAudio = (audioUrl, mediaDuration) => {
    if (currentAudioUrl === audioUrl && isPlaying) {
      // Pause the audio if it's already playing
      setIsPlaying(false);
      clearTimeout(audioTimer);
    } else {
      // Start playing the new audio
      setCurrentAudioUrl(audioUrl);
      setIsPlaying(true);
      setAudioDuration(mediaDuration);

      // Clear any existing timer
      if (audioTimer) {
        clearTimeout(audioTimer);
      }

      // Set a new timer to stop the audio after the media duration
      const timer = setTimeout(() => {
        setIsPlaying(false);
      }, moment.duration(mediaDuration).asMilliseconds());

      setAudioTimer(timer);
    }
  };

  useEffect(() => {
    // Cleanup function to clear the audio timer when the component unmounts
    return () => {
      if (audioTimer) {
        clearTimeout(audioTimer);
      }
    };
  }, [audioTimer]);

  const handlePauseAudio = () => {
    setIsPlaying(false);
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <LazyLoadImageComponent
                  src={
                    userData.profile_url == 2
                      ? userData.profile_pic
                      : userData.profile_pic
                      ? img_path + userData.profile_pic
                      : icon
                  }
                  alt="profile image"
                ></LazyLoadImageComponent>

                {userData.online === "isOnline" && (
                  <div className={Styles.redIcon}></div>
                )}
                <div className={Styles.profileText}>
                  <h2>{userData.user_name}</h2>
                  {userData.online === "isOnline" ? (
                    <p>Active</p>
                  ) : (
                    <p>Last seen {timestampToDate(userData.timestamp)}</p>
                  )}
                </div>
                <div className="dropdown">
                  <ul
                    className="dropbtn icons btn-right showLeft"
                    onClick={toggleDropdown}
                  >
                    <BsThreeDotsVertical />
                  </ul>
                  {dropdownOpen && (
                    <div id="myDropdown" className="dropdown-content">
                      <h1
                        type="button"
                        onClick={() =>
                          handleShowModal(
                            blockCurrentStatus == 1 || blockCurrentStatus == 3
                              ? "unblock"
                              : "block",
                            id
                          )
                        }
                      >
                        {blockCurrentStatus == 1 || blockCurrentStatus == 3
                          ? "Unblock"
                          : "Block"}
                      </h1>
                      <h1
                        type="button"
                        onClick={() => handleShowModal("delete")}
                      >
                        Delete chat
                      </h1>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.scrollTopChat}>
                <div
                  className="commTextDiv scrollheight"
                  ref={chatContainerRef}
                >
                  {chat && chat.length > 0 ? (
                    <>
                      {chat &&
                        chat
                          .slice()
                          .reverse()
                          .map((item, index) => (
                            <div
                              className={
                                item.user_id !== currentUser.userID
                                  ? "commTextDivInner"
                                  : "commTextReply"
                              }
                              key={index}
                            >
                              {item.user_id !== currentUser.userID ? (
                                <>
                                  <div className="imgIcon">
                                    <LazyLoadImageComponent
                                      src={
                                        item.profile_pic
                                          ? img_path + item.profile_pic
                                          : icon
                                      }
                                      alt="profile image"
                                      style={{ height: "52px", width: "52px" }}
                                    ></LazyLoadImageComponent>
                                  </div>
                                  <div className="messageIcon">
                                    <div className="messageIconInner">
                                      <h2>{item.message}</h2>
                                      {item.type === "Audio" && (
                                        <div className="audioMessage">
                                          <p style={{ marginLeft: "10px" }}>
                                            {moment
                                              .utc(
                                                item.media_duration,
                                                "HH:mm:ss"
                                              )
                                              .format("mm:ss")}
                                          </p>

                                          <LazyLoadImageComponent
                                            src={recordIcon}
                                            alt="Record"
                                            style={{
                                              maxWidth: "250px",
                                              height: "24px",
                                              paddingLeft: "5px",
                                            }}
                                          ></LazyLoadImageComponent>
                                          {currentAudioUrl === item.file_name &&
                                          isPlaying ? (
                                            <FaPauseCircle
                                              onClick={handlePauseAudio}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                                color: "red",
                                              }}
                                            />
                                          ) : (
                                            <FaCirclePlay
                                              onClick={() =>
                                                handlePlayAudio(
                                                  item.file_name,
                                                  item.media_duration
                                                )
                                              }
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                                color: "red",
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                      {item.image_url &&
                                        (item.type === "Video" ? (
                                          <ReactPlayer
                                            width="100%"
                                            url={item.image_url}
                                            controls
                                          />
                                        ) : (
                                          <img
                                            className="chatImage"
                                            src={item.image_url}
                                            alt="Attachment"
                                            onClick={() =>
                                              handleImageClick(item.image_url)
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        ))}

                                      <div className="messageIconShape"></div>
                                    </div>
                                    <p>
                                      @{item.user_name} -{" "}
                                      {ChatTimestampToDate(item.timestamp)}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="imgIcont">
                                    <span></span>
                                  </div>
                                  <div className="commTextDivInner">
                                    <div className="messageIcon">
                                      <div className="messageIconInner">
                                        <h2
                                          dangerouslySetInnerHTML={{
                                            __html: ChatTextBrTageFormate(
                                              item.message
                                            ),
                                          }}
                                        ></h2>

                                        {item.image_url &&
                                          (item.type === "Video" ? (
                                            <ReactPlayer
                                              width="100%"
                                              url={item.image_url}
                                              controls
                                            />
                                          ) : (
                                            <img
                                              className="chatImage"
                                              src={item.image_url}
                                              alt="Attachment"
                                              onClick={() =>
                                                handleImageClick(item.image_url)
                                              }
                                              style={{ cursor: "pointer" }}
                                            />
                                          ))}

                                        <div className="readTick">
                                          {renderReadTick(item.msg_read_tick)}
                                        </div>
                                        <div className="messageIconShapeRight"></div>
                                      </div>
                                      <p>
                                        @{item.user_name} -{" "}
                                        {ChatTimestampToDate(item.timestamp)}
                                      </p>
                                    </div>
                                    <div className="imgIconRight">
                                      <LazyLoadImageComponent
                                        src={
                                          item.profile_pic
                                            ? img_path + item.profile_pic
                                            : icon
                                        }
                                        alt="profile image"
                                        style={{
                                          height: "52px",
                                          width: "52px",
                                        }}
                                      ></LazyLoadImageComponent>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                    </>
                  ) : (
                    <div className={styles.records_Found_Message}>
                      <h2>{t("NO_MESSAGE_FOUND")}</h2>
                    </div>
                  )}
                </div>
              </div>

              <div className="commentsMainDiv">
                <div className="selectimageshow">
                  {previewImage && (
                    <div className="showSelectedImg">
                      <div className="showSelectedImgInner">
                        {previewImage.type === "image" ? (
                          <img
                            src={previewImage.url}
                            alt="Preview"
                            onClick={() => handleImageClick(previewImage.url)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <ReactPlayer
                            url={previewImage.url}
                            controls
                            width="100%"
                            height="100%"
                          />
                        )}
                        <div className="imageCrossIcon">
                          <ImCross onClick={handleRemoveImage} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {blockCurrentStatus > 0 ? (
                  <p>{blockStatus}</p>
                ) : (
                  <div className="commentsInput">
                    <div className="commentsInputLeft">
                      <FaCirclePlus
                        onClick={() => fileInputRef.current.click()}
                      />
                      <input
                        type="file"
                        accept="image/*,video/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                      />
                    </div>

                    <div className="commentsInputCenter">
                      <Form>
                        <Form.Control
                          as="textarea"
                          placeholder={t("WRITE_A_MESSAGE")}
                          rows={1}
                          name="comment"
                          value={comment}
                          onChange={handleCommentChange}
                          ref={textareaRef}
                          style={{
                            overflow: "auto",
                            resize: "none",
                            backgroundColor: "#efefef",
                            border: "none",
                          }}
                        />
                      </Form>
                    </div>
                    <span>
                      <FaChevronRight type="button" onClick={onSubmit} />
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ImagePreviewModal
        show={showModal}
        handleClose={handleCloseModal}
        imageUrl={modalImageUrl}
      />

      <ConfirmationModal
        show={blockModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmAction}
        action={action}
      />
    </div>
  );
}

export default Chat;
