import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  age_to,
  ethnicity,
  gender,
  isLimit,
  MISSING_TYPE,
  offset,
} from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import commentIcon from "../../../assets/Images/Comment.svg";
import viewIcon from "../../../assets/Images/Views.svg";
import bookmarkIcon from "../../../assets/Images/Bookmark.svg";
import trendIcon from "../../../assets/Images/Trend.svg";
import styles from "../../UserModule/YourReport/YourReport.module.css";
import { formatTime } from "../../../utils/manageConstant";
import { useLocation, useNavigate } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";

import defaultImage from "../../../assets/Images/defaultImg.webp";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import AdBannerRightSmall from "../AdBannerRightSmall";

const MissingAndWanted = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [totalReportCount, setTotalReportCount] = useState();
  const [page, setPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  //   getTimeline
  async function MissingPersionList(page = 1) {
    setLoader(true);
    const type =
      location.pathname == "/missing"
        ? MISSING_TYPE.MISSING
        : MISSING_TYPE.WANTED;
    await SublyApi.MissingPersionTooltip(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit,
      ethnicity,
      gender,
      age_to,
      type
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response?.data?.reports;
        setTotalReportCount(response?.data?.total_reports);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setTimeLineData(sortedReports);
          } else {
            setTimeLineData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setTimeLineData([]);
        }

        const Count = response.data?.total_reports || 0;

        if (type === MISSING_TYPE.MISSING) {
          dispatch(
            updateSettingCount({
              keyName: "total_missing_person",
              value: Count,
            })
          );
        } else if (type === MISSING_TYPE.WANTED) {
          dispatch(
            updateSettingCount({
              keyName: "total_wanted",
              value: Count,
            })
          );
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }
  useEffect(() => {
    MissingPersionList();
  }, [location.pathname]);

  const TimeLineViewProfile = (id) => {
    navigate(`/profile-detail/${id}`);
  };

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    MissingPersionList(page + 1);
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={8} md={8}>
              <div className={styles.scrollTopTimeline}>
                {timeLineData && timeLineData.length > 0 ? (
                  timeLineData.map((item, index) => (
                    <div className={styles.reportSection} key={index}>
                      <div className={styles.reportSectionLeft}>
                        <div
                          className={styles.reportImg}
                          onClick={() => TimeLineViewProfile(item.userID)}
                        >
                          <LazyLoadImageComponent
                            src={
                              item?.profile_picture
                                ? item?.profile_picture
                                : defaultImage
                            }
                            alt="profile image"
                          ></LazyLoadImageComponent>
                        </div>
                        <div
                          className={styles.reportText}
                          onClick={() => TimeLineViewDetails(item.reportID)}
                        >
                          <p>
                            <strong>
                              {item.category_name == undefined ||
                              item.category_name == "" ? (
                                ""
                              ) : (
                                <>
                                  {item.category_name}
                                  &nbsp; - &nbsp;
                                </>
                              )}
                            </strong>

                            {item.location == undefined ||
                            item.location == "" ? (
                              ""
                            ) : (
                              <>
                                {item.location}
                                &nbsp; - &nbsp;
                              </>
                            )}

                            <span>
                              {item.date} {t("At")}{" "}
                              {item.time ? formatTime(item.time) : t("UNKNOWN")}
                            </span>
                          </p>
                          <div className={styles.reportShow}>
                            <p>
                              <img src={commentIcon} alt="comment icon" />
                              &nbsp;&nbsp;
                              {item.comment_count == ""
                                ? 0
                                : item.comment_count}
                            </p>
                            <p>
                              <img src={viewIcon} alt="view icon" />
                              &nbsp;&nbsp;
                              {item.view_count == "" ? 0 : item.view_count}
                            </p>
                            <p>
                              <img src={bookmarkIcon} alt="bookmark icon" />
                              &nbsp;&nbsp;
                              {item.bookmark_count == ""
                                ? 0
                                : item.bookmark_count}
                            </p>
                            <p>
                              <img src={trendIcon} alt="trend icon" />
                              &nbsp;&nbsp;
                              {item.trend_count == "" ? 0 : item.trend_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="records_Found">
                      <h2>{t("NO_REC_FOUND")}</h2>
                    </div>
                  </>
                )}

                {timeLineData?.length < totalReportCount ? (
                  <div className="loadMoreSection">
                    <button className="loadMoreBtn" onClick={loadMoreReports}>
                      {t("LOAD_MORE")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={4} md={4}>
              <AdBannerRightSmall /> <AdBannerRightSmall />{" "}
              <AdBannerRightSmall />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MissingAndWanted;
