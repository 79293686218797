import { t } from "i18next";
import { sanitizeHtmlTags } from "./sanitizeHtmlTags";
import { MAX_NUMBER } from "./Constants";

// function for apply validation in phoneNumber field
export const validateNumber = (phoneNo) => {
  if (!phoneNo) {
    return `${t("PHONE_NUMBER_REQUIRED")}`;
  }
  if (!phoneNo) {
    return `${t("MOBILE_VALID")}`;
  }
  const minPhoneNumberLength = MAX_NUMBER.NINE;
  const maxPhoneNumberLength = MAX_NUMBER.TEN;
  if (phoneNo.length < minPhoneNumberLength) {
    return `${t("MOBILE_LENGTH")}`;
  }
  if (phoneNo.length > maxPhoneNumberLength) {
    return `${t("PHONE_MAXLENGTH")}`;
  }
};

// function for apply validation using useform
export const Validation = {
  name: {
    required: {
      value: true,
      message: `${t("NAME_REQUIRED")}`,
    },
    minLength: {
      value: 2,
      message: `${t("PUBLICNAME_MINENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  email: {
    required: {
      value: true,
      message: `${t("VALID_EMAIL")}`,
    },
    pattern: {
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: `${t("VALID_EMAIL")}`,
    },
    ...sanitizeHtmlTags(),
  },

  email1: {
    pattern: {
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: `${t("VALID_EMAIL")}`,
    },
    ...sanitizeHtmlTags(),
  },

  password: {
    required: {
      value: true,
      message: `${t("PASSWORD_REQUIRED")}`,
    },

    minLength: {
      value: 5, // Minimum of 5 characters for password
      message: `${t("PASSWORD_MINLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  confirmPassword: {
    required: {
      value: true,
      message: `${t("CONF_PASS_REQUIRED")}`,
    },
    minLength: {
      value: 5, // Minimum of 5 characters for password
      message: `${t("PASSWORD_MINLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  otp: {
    required: {
      value: true,
      message: `${t("OTP_REQUIRED")}`,
    },
    ...sanitizeHtmlTags(),
  },

  location: {
    required: {
      value: true,
      message: `${t("LOCATION_REQUIRED")}`,
    },
    ...sanitizeHtmlTags(),
  },

  address: {
    required: {
      value: true,
      message: `${t("ADDRESS_REQUIRED")}`,
    },
    ...sanitizeHtmlTags(),
  },

  description: {
    maxLength: {
      value: 200,
      message: `${t("INCIDENT_MAXLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  description1: {
    required: {
      value: true,
      message: `${t("DEATILS_REQUIRED")}`,
    },
    minLength: {
      value: 200,
      message: `${t("INCIDENT_MINENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  publicName: {
    required: {
      value: true,
      message: `${t("PUBLICNAME_REQUIRED")}`,
    },
    minLength: {
      value: 2,
      message: `${t("PUBLICNAME_MINENGTH")}`,
    },
    maxLength: {
      value: 50,
      message: `${t("PUBLICNAME_MAXLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },

  userName: {
    required: {
      value: true,
      message: `${t("USERNAME_REQUIRED")}`,
    },
    maxLength: {
      value: 15,
      message: `${t("USERNAME_MAXLENGTH")}`,
    },

    ...sanitizeHtmlTags(),
  },

  dateValidate: {
    required: {
      value: true,
      message: `${t("DATE_REQUIRED")}`,
    },
  },

  url: {
    pattern: {
      value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/,
      message: "Enter a valid URL",
    },
  },
  addressDescriptor: {
    required: {
      value: true,
      message: `${t("ADDRESS_DESCRIPTOR_REQUIRED")}`,
    },
    ...sanitizeHtmlTags(),
  },

  youName: {
    required: {
      value: true,
      message: `${t("USERNAME_REQUIRED")}`,
    },
    maxLength: {
      value: 15,
      message: `${t("USERNAME_MAXLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },
  friendName: {
    required: {
      value: true,
      message: `${t("FRIEND_NAME_REQUIRED")}`,
    },
    maxLength: {
      value: 15,
      message: `${t("FRIEND_NAME_MAXLENGTH")}`,
    },
    ...sanitizeHtmlTags(),
  },
  friendEmail: {
    required: {
      value: true,
      message: `${t("VALID_EMAIL")}`,
    },
    pattern: {
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: `${t("VALID_EMAIL")}`,
    },
    ...sanitizeHtmlTags(),
  },
  // PhoneNumber: {
  //   pattern: {
  //     // value: /^[0-9]{1,10}$/,
  //     // message: `${t("PHONE_NUMBER_PROFILE")}`,
  //   },
  //   ...sanitizeHtmlTags(),
  // },

  PhoneNumber: {
    minLength: {
      value: 9,
      message: `${t("MOBILE_LENGTH_TELEPHONE")}`,
    },
    maxLength: {
      value: 10,
      message: `${t("PHONE_MAXLENGTH_TELEPHONE")}`,
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "Phone number must only contain numbers",
    },
    ...sanitizeHtmlTags(),
  },
};
