import React, { memo } from "react";
import styles from "./YourReport.module.css";
import { useNavigate } from "react-router-dom";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import { useTranslation } from "react-i18next";
import anonymous from "../../../assets/Images/anonymous.svg";

const ReportDetailsProfile = memo(
  ({
    profileDetails,
    userId,
    reportDetails,
    REPORT_AS,
    currentUser,
    PRIVATE_POFILE,
    isFollowing,
    FollowersCount,
    reported_as,
  }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const TimeLineViewProfile = (username) => {
      navigate(`/${username}`);
    };

    return (
      <div>
        {profileDetails && profileDetails?.length > 0 && (
          <div className={styles.dashboardInfo}>
            <div
              className={styles.dashboardImg}
              style={{ cursor: "pointer", marginRight: "15px" }}
              onClick={
                reported_as == REPORT_AS
                  ? () => TimeLineViewProfile(profileDetails[0].username)
                  : undefined
              }
            >
              {reported_as == REPORT_AS ? (
                <LazyLoadImageComponent
                  src={profileDetails && profileDetails[0].profile_picture}
                  alt="profile image"
                />
              ) : (
                <>
                  <LazyLoadImageComponent
                    src={anonymous}
                    className={styles.anonymousImage}
                    alt="anonymous image"
                  />
                </>
              )}
            </div>
            {reported_as == REPORT_AS ? (
              <div className={styles.dashboardText}>
                <p
                  onClick={
                    reported_as == REPORT_AS
                      ? () => TimeLineViewProfile(profileDetails[0].username)
                      : undefined
                  }
                  style={{ cursor: "pointer" }}
                >
                  @
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].username}
                </p>
                <h3
                  onClick={
                    reported_as == REPORT_AS
                      ? () => TimeLineViewProfile(profileDetails[0].username)
                      : undefined
                  }
                  style={{ cursor: "pointer" }}
                >
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].name}
                </h3>

                <div className={styles.dashboardInfoBtn}>
                  {currentUser.userID !== profileDetails[0]?.userID &&
                    reported_as !== PRIVATE_POFILE.reported_as && (
                      <>
                        {isFollowing ? (
                          <button
                            className={styles.followButton}
                            onClick={() => {
                              FollowersCount(userId);
                            }}
                          >
                            {t("FOLLOWING")}
                          </button>
                        ) : (
                          <button
                            className={styles.unfollowButton}
                            onClick={() => {
                              FollowersCount(userId);
                            }}
                          >
                            {t("FOLLOW")}
                          </button>
                        )}
                      </>
                    )}

                  <h5>
                    <span>
                      <strong>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].follower}
                      </strong>
                      &nbsp;
                      {t("FOLLOWERS")} |&nbsp;
                    </span>
                    <span>
                      <strong>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].following}
                      </strong>
                      &nbsp;
                      {t("FOLLOWING")}
                    </span>
                  </h5>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.anonymousTestDetails}>
                  <h3>{t("ANONYMS")}</h3>
                  <p>{t("USER_IDENTITY_WITHELD")}</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ReportDetailsProfile;
