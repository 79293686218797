import { Container, Form, Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import styles from "../Header/Header.module.css";
import logo from "../../assets/Images/logo.svg";
import logo1 from "../../assets/Images/logo2.svg";
import { useEffect, useRef, useState } from "react";
import { MdCancel } from "react-icons/md";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import profile from "../../assets/Images/defaultImg.webp";
import { MdArrowDropDown } from "react-icons/md";
import HeaderButton from "../../commonComponent/CommonButton/HeaderButton";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "./DropDown";
import JoinPopup from "./JoinPopup";
import SearchFilter from "./SearchFilter";
import {
  setReportExit,
  setReportExitUrl,
  setSearchCountApi,
  setSearchFilterQuery,
} from "../../store/slices/Userslice";
import {
  MessageCount,
  GetAlertCount,
  updateUserData,
} from "../../firebase/FireBaseChat";
import { FiMapPin } from "react-icons/fi";
import { HiOutlineBell } from "react-icons/hi";
import { convertToNumber } from "../../utils/manageConstant";

//---------function for header----------
function Header() {
  const { userData, searchFilterQuery, userToken, currentUser } = useSelector(
    (state) => state.user
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const reportExitModal = useSelector((state) => state.user.reportExitModal);
  const [showTooltip, setShowTooltip] = useState(false);
  const [navTooltip, setnavTooltip] = useState(true);

  const tooltipRef = useRef(null);
  const [showJoin, setShowJoin] = useState(false);
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const tooltipSearchRef = useRef(null);
  const searchContainerRef = useRef(null);
  const [search, setSearch] = useState("");
  const { searchCountApi } = useSelector((state) => state.user);
  const params = new URLSearchParams(window.location.search);
  const tagName = params.get("tagName");
  const navbarToggleRef = useRef(null);

  // const [hasPermission, setHasPermission] = useState(
  //   Notification.permission === "granted"
  // );

  // useEffect(() => {
  //   // Request notification permission when the component mounts
  //   if (Notification.permission === "default") {
  //     Notification.requestPermission().then((permission) => {
  //       setHasPermission(permission === "granted");
  //     });
  //   }
  // }, []);

  const handleSearch = (e) => {
    dispatch(setSearchFilterQuery(e.target.value));
  };

  async function UpdateFirebaseUser(user, updObject) {
    updateUserData(user.userID, updObject);
  }

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      const updateObject = {
        name: userData.name,
        on_chat_userid: "0-0",
        online: "isOnline",
        profile_pic: userData.profile_image,
        // firebase_token:firebase_token,
        profile_url: userData.is_profile_url,
        user_id: userData.userID,
        user_name: userData.username,
      };
      UpdateFirebaseUser(userData, updateObject);
    }
  }, [userData]);

  const [count, setCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const handleClick = (event, url) => {
    if (window.innerWidth <= 991) {
      navbarToggleRef.current.click();
    }
    event.preventDefault();
    dispatch(setReportExitUrl(url));
    if (reportExitModal == true) {
      dispatch(setReportExit(true));
    } else {
      navigate(url);
    }
  };

  const toggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  const toggleTooltipSearch = () => {
    setShowSearchFilter((prev) => !prev);
  };

  const handleClickOutsideTooltip = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  const handleClickOutsideSearch = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (tagName) {
      dispatch(setSearchFilterQuery(tagName));
      performSearch();
      setIsVisible(true);
    }
  }, [tagName]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideTooltip);
    document.addEventListener("mousedown", handleClickOutsideSearch);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideTooltip);
      document.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  }, []);

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      if (Object.keys(currentUser).length !== 0) {
        performSearch();
      } else {
        setShowJoin(true);
      }
    }
  };

  const performSearch = () => {
    dispatch(setSearchCountApi(searchCountApi + 1));
    navigate("/search-result");
  };

  useEffect(() => {
    dispatch(setSearchFilterQuery(""));
  }, []);

  useEffect(() => {
    const unsubscribe = MessageCount(currentUser, setCount);

    // Return a cleanup function to detach the listener when component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUser, setCount]);

  const numberCount = convertToNumber(count);

  useEffect(() => {
    const unsubscribe = GetAlertCount(currentUser, setAlertCount);

    // Return a cleanup function to detach the listener when component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUser, setAlertCount]);

  const alertCountNumber = convertToNumber(alertCount);

  return (
    <section className={styles.headerSection}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand className={styles.headerBrand}>
            <div className={styles.headerLogo}>
              <NavLink onClick={(e) => handleClick(e, "/")} to="/">
                <img src={logo} alt="logo" className={styles.mainLogo} />
                <img src={logo1} alt="logo" className={styles.responseLogo} />
              </NavLink>
            </div>
          </Navbar.Brand>
          {isVisible == false && (
            <>
              <Navbar.Toggle
                className={styles.navbarToggle}
                ref={navbarToggleRef}
              >
                <div className={styles.searchOptionToggle}>
                  <FaSearch
                    className={styles.headerIcon}
                    onClick={toggleVisibility}
                  />
                  <HeaderButton
                    type="button"
                    onClick={() => navigate("/report")}
                    label={t("REPORT")}
                  />
                  {/* manage header after login case */}
                  {location.pathname == "/login" ||
                  location.pathname == "/join" ||
                  location.pathname == "/password-reminder" ||
                  location.pathname == "/password-success" ||
                  Object.keys(currentUser).length === 0 ? (
                    <HeaderButton
                      type="button"
                      onClick={() => navigate("/join")}
                      label={t("JOIN")}
                    />
                  ) : (
                    <>
                      {Object.keys(currentUser).length !== 0 && (
                        <div
                          className={styles.profileImg}
                          onClick={toggleTooltip}
                          ref={tooltipRef}
                        >
                          <MdArrowDropDown />
                          <img
                            src={
                              userData?.profile_picture
                                ? userData?.profile_picture
                                : profile
                            }
                            alt="profile-image"
                          />
                          {showTooltip && <DropDown />}
                        </div>
                      )}
                    </>
                  )}
                  <FiMenu />
                </div>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <div className={styles.navItems}>
                  <Nav className={styles.activeShow}>
                    <div
                      onClick={(e) => {
                        handleClick(e, "/map");
                      }}
                    >
                      <FiMapPin />
                      {t("MAP")}
                    </div>
                  </Nav>
                  <Nav className={styles.activeShow}>
                    <div
                      onClick={(e) => {
                        if (userToken) {
                          handleClick(e, "/timeline");
                        } else {
                          handleClick(e, "/timelines");
                        }
                      }}
                    >
                      <FiMenu />
                      {t("TIMELINE")}
                    </div>
                  </Nav>
                  <Nav className={styles.activeShow}>
                    <div
                      onClick={(e) => {
                        if (userToken) {
                          handleClick(e, "/alerts");
                        } else {
                          setShowJoin(true);
                        }
                      }}
                    >
                      <HiOutlineBell />
                      {t("ALERT")}
                    </div>
                    {}

                    {userToken && (
                      <>
                        {userData && userData.total_alerts > 0 && (
                          <div className={styles.messageCountCercle}>
                            <span>
                              {" "}
                              {userData &&
                                (userData.total_alerts == ""
                                  ? 0
                                  : userData.total_alerts)}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </Nav>
                  <Nav className={styles.activeShow}>
                    <div
                      onClick={(e) => {
                        if (userToken) {
                          handleClick(e, "/messages");
                        } else {
                          setShowJoin(true);
                        }
                      }}
                      className={styles.navLinkWrapper}
                    >
                      <div className={styles.navLinkContent}>
                        <FiMail className={styles.headerIcon} />
                        <span className={styles.navOptionHide}>
                          {t("MESSAGES")}
                        </span>
                        {userToken && (
                          <>
                            {numberCount > 0 && (
                              <div className={styles.messageCountCercleNew}>
                                <span>{numberCount}</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Nav>
                </div>
                <div className={styles.searchOption}>
                  <FaSearch
                    className={styles.headerIcon}
                    onClick={toggleVisibility}
                  />
                  <HeaderButton
                    type="button"
                    onClick={() => {
                      if (userToken) {
                        navigate("/report");
                      } else {
                        setShowJoin(true);
                      }
                    }}
                    label={t("REPORT")}
                  />

                  {/* manage header after login case */}
                  {location.pathname == "/login" ||
                  location.pathname == "/join" ||
                  location.pathname == "/password-reminder" ||
                  location.pathname == "/password-success" ||
                  Object.keys(currentUser).length === 0 ? (
                    <HeaderButton
                      type="button"
                      onClick={() => navigate("/join")}
                      label={t("JOIN")}
                    />
                  ) : (
                    <>
                      {Object.keys(currentUser).length !== 0 && (
                        <div
                          className={styles.profileImg}
                          onClick={toggleTooltip}
                          ref={tooltipRef}
                        >
                          <MdArrowDropDown />
                          <img
                            src={
                              userData?.profile_picture
                                ? userData?.profile_picture
                                : profile
                            }
                            alt="profile-image"
                          />
                          {showTooltip && <DropDown />}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Navbar.Collapse>
            </>
          )}
          {isVisible && (
            <div className={styles.searchCancel} ref={searchContainerRef}>
              <div className={styles.searchBox}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    value={searchFilterQuery}
                    className={styles.searchInput}
                    placeholder={t("SEARCH_PLACEHOLDER")}
                    onChange={(e) => handleSearch(e)}
                    onKeyPress={handleSearchKeyPress}
                  />
                </Form.Group>
                <FiSearch />
              </div>
              <div className={styles.searchOptionToggle}>
                <HiOutlineAdjustmentsHorizontal
                  onClick={toggleTooltipSearch}
                  ref={tooltipSearchRef}
                />
                {showSearchFilter && (
                  <SearchFilter
                    tooltipSearchRef={tooltipSearchRef}
                    toggleTooltipSearch={toggleTooltipSearch}
                    search={search}
                    setShowJoin={setShowJoin}
                  />
                )}
                <MdCancel onClick={toggleVisibility} />
              </div>
            </div>
          )}
        </Container>
      </Navbar>

      <JoinPopup showJoin={showJoin} setShowJoin={setShowJoin} />
    </section>
  );
}
export default Header;
