import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./YourProfile.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import { isLimit, offset, REPORT_AS } from "../../../utils/Constants";
import Loader from "../../../utils/Loader/Loader";
import { formatTime } from "../../../utils/manageConstant";
import commentIcon from "../../../assets/Images/Comment.svg";
import viewIcon from "../../../assets/Images/Views.svg";
import bookmarkIcon from "../../../assets/Images/Bookmark.svg";
import trendIcon from "../../../assets/Images/Trend.svg";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { BsTwitterX } from "react-icons/bs";
import { TiSocialFacebook } from "react-icons/ti";
import ReactPlayer from "react-player";
import { getUserById } from "../../../firebase/FireBaseChat";
import { SlArrowRight } from "react-icons/sl";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import defaultImage from "../../../assets/Images/defaultImg.webp";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import anonymous from "../../../assets/Images/anonymous.svg";

function ProfileDetail() {
  const { userToken, currentUser, userData } = useSelector(
    (state) => state.user
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileDetails, setProfileDetails] = useState([]);

  const [isFollowing, setIsFollowing] = useState(0);

  const [loader, setLoader] = useState(false);
  const { id } = useParams();

  const [totalReports, setTotalReports] = useState();
  const [showDataList, setShowDataList] = useState([]);
  const [profileUserId, setProfileUserId] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [otherUserInfo, setOtherUserInfo] = useState("");

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  async function getOtherUserDetailFirebase() {
    const otherUserInfo = await getUserById(profileUserId);
    if (otherUserInfo) {
      setOtherUserInfo(otherUserInfo);
    }
  }

  useEffect(() => {
    getOtherUserDetailFirebase();
  }, [profileUserId]);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  //========= function for handle followers count=======
  async function FollowersCount(otherUserId) {
    setLoader(true);
    await SublyApi.followingUpdate(userToken, otherUserId).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        PublicProfileList(id);
        setIsFollowing((prev) => (prev === 0 ? 1 : 0));
        const successMessage =
          isFollowing == 1
            ? t("PROFILE_UNFOLLOW_SUCCESSFULL")
            : t("PROFILE_FOLLOW_SUCCESSFULL");

        Toster(successMessage, "success");

        if (isFollowing == 0) {
          dispatch(
            updateSettingCount({
              keyName: "following_count",
              value: parseInt(userData.following_count) + 1,
            })
          );
        } else {
          if (parseInt(userData.following_count) > 0) {
            dispatch(
              updateSettingCount({
                keyName: "following_count",
                value: parseInt(userData.following_count) - 1,
              })
            );
          }
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  //=====function for calling profile detail api======
  async function PublicProfileList(id) {
    setLoader(true);
    await SublyApi.PublicProfile(userToken, id).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const userdetails = response.data?.userdetails;
        if (userdetails && userdetails.length > 0) {
          setProfileDetails(userdetails);
          setProfileUserId(userdetails[0].userID);
          setIsFollowing(userdetails[0].follow_by_you);
        } else {
          setProfileDetails([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  //========== function for calling other user report list api===========
  async function userReport(page = 1) {
    setLoader(true);
    await SublyApi.getOtherReport(
      userToken,
      profileUserId,
      isLimit,
      offset + (page - 1) * isLimit
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.userReports;
        setTotalReports(response?.data?.total_reports);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setShowDataList(sortedReports);
            setFilteredData(sortedReports);
          } else {
            setShowDataList((prev) => [...prev, ...sortedReports]);
            setFilteredData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setShowDataList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    if (profileUserId) {
      userReport();
    }
    if (userToken) {
      PublicProfileList(id);
    } else {
      getprofileList(id);
    }
  }, [profileUserId]);

  //========= function for handle loadmore=========
  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    userReport(page + 1);
  };

  const getSecondURL = (url) => {
    // Split the URL by 'https://'
    const parts = url.split("https://");

    // Filter valid parts and reconstruct URLs
    const urls = parts
      .filter((part) => part.trim())
      .map((part) => `https://${part}`); // Rebuild valid URLs

    // Return the second URL if available, otherwise return the first
    return urls[1] || urls[0] || "";
  };

  async function getprofileList(id) {
    setLoader(true);
    await SublyApi.userProfileGuest(id).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (
        response &&
        response.data &&
        response.status_code === STATUS_CODES.SUCCESS
      ) {
        setProfileDetails(response?.data?.userdetails);
        setProfileUserId(response?.data?.userdetails[0].userID);
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <div>
              <div className={styles.topHeader}>
                <NavLink to="/" className="userActives">
                  <p style={{ cursor: "initial" }}>
                    {t("HOMECRIME")}{" "}
                    <span>
                      {profileDetails &&
                        profileDetails.length > 0 &&
                        profileDetails[0].name}
                    </span>
                  </p>
                </NavLink>
              </div>
            </div>
            <Col sm={12} md={5} lg={5}>
              <div className={styles.dashboardInfo}>
                <div className={styles.imageSection}>
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].profile_picture && (
                      <>
                        <LazyLoadImageComponent
                          src={profileDetails[0].profile_picture}
                          alt="profile image"
                        ></LazyLoadImageComponent>

                        {userToken &&
                          Object.keys(otherUserInfo).length !== 0 &&
                          otherUserInfo.online == "isOnline" && (
                            <div className={styles.greenIconOnlineMain}>
                              <span></span>
                            </div>
                          )}
                      </>
                    )}
                </div>

                <div className={styles.textSection}>
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].username && (
                      <p>@{profileDetails[0].username}</p>
                    )}

                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].name && <h4>{profileDetails[0].name}</h4>}
                </div>

                {profileDetails.length > 0 && (
                  <div className={styles.dashboardText}>
                    <div className={styles.dashboardInfoBtn}>
                      <h5>
                        <span>
                          <strong>
                            {profileDetails &&
                              profileDetails.length > 0 &&
                              profileDetails[0].follower}
                          </strong>
                          &nbsp;
                          {t("FOLLOWERS")} |&nbsp;
                        </span>
                        <span>
                          <strong>
                            {profileDetails &&
                              profileDetails.length > 0 &&
                              profileDetails[0].following}
                          </strong>
                          &nbsp;
                          {t("FOLLOWING")}
                        </span>
                      </h5>
                      {userToken ? (
                        <>
                          <span className={styles.followingBtnProfile}>
                            {currentUser.userID !==
                              profileDetails[0]?.userID && (
                              <>
                                {isFollowing ? (
                                  <button
                                    className={styles.followButton}
                                    onClick={() =>
                                      FollowersCount(profileDetails[0]?.userID)
                                    }
                                    disabled={loader}
                                  >
                                    {t("FOLLOWING")}
                                  </button>
                                ) : (
                                  <button
                                    className={styles.unfollowButton}
                                    onClick={() =>
                                      FollowersCount(profileDetails[0]?.userID)
                                    }
                                    disabled={loader}
                                  >
                                    {t("FOLLOW")}
                                  </button>
                                )}
                              </>
                            )}
                            {currentUser.userID !==
                              profileDetails[0]?.userID && (
                              <>
                                <button
                                  className={styles.sendMessageBtn}
                                  onClick={() =>
                                    navigate(
                                      `/messages/${profileDetails[0]?.userID}`
                                    )
                                  }
                                >
                                  {t("SEND_MESSAGE")}
                                </button>
                              </>
                            )}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className={styles.emailShow}>
                        <p>
                          {profileDetails &&
                            profileDetails.length > 0 &&
                            profileDetails[0].profile_description}
                        </p>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].profile_contact_number && (
                            <h5>{profileDetails[0].profile_contact_number}</h5>
                          )}
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].profile_email && (
                            <h6>{profileDetails[0].profile_email}</h6>
                          )}
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].website_address && (
                            <h4>{profileDetails[0].website_address}</h4>
                          )}
                      </div>
                      <div className={styles.linksAdd}>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].facebook_profile_link && (
                            <NavLink
                              to={getSecondURL(
                                profileDetails[0].facebook_profile_link
                              )}
                            >
                              <TiSocialFacebook />
                            </NavLink>
                          )}

                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].twitter_profile_link && (
                            <NavLink
                              to={profileDetails[0].twitter_profile_link}
                            >
                              <BsTwitterX className={styles.twitterLinks} />
                            </NavLink>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col sm={12} md={7} lg={7}>
              <div className={styles.profileReport}>
                {totalReports} {t("REPORTS")}{" "}
                <div className={styles.datefilter}>
                  <div
                    className={`dropdown-container ${styles.dropdownContainer}`}
                  ></div>
                </div>
              </div>
              <div className={styles.scrollTopYourReport}>
                {showDataList && showDataList.length > 0 ? (
                  showDataList.map((item, index) => (
                    <div key={index}>
                      <div className={styles.reportSectionLeft}>
                        <div className={styles.reportSection}>
                          <div
                            className={styles.reportImg}
                            onClick={() => TimeLineViewDetails(item.reportID)}
                          >
                            {item && item?.reported_as == REPORT_AS?.PROFILE ? (
                              <LazyLoadImageComponent
                                src={item?.profile_picture}
                                alt="profile image"
                              />
                            ) : (
                              <>
                                <LazyLoadImageComponent
                                  src={anonymous}
                                  className={styles.anonymousImage}
                                  alt="anonymous image"
                                />
                              </>
                            )}

                            {/* <LazyLoadImageComponent
                              src={
                                item?.profile_picture
                                  ? item?.profile_picture
                                  : defaultImage
                              }
                              alt="profile report image"
                            ></LazyLoadImageComponent> */}
                          </div>
                          <div
                            className={`${styles.reportText} ${styles.reportTextss}`}
                          >
                            <p>
                              <strong>{item.category_name}</strong>
                              {item.location != "undefined" && (
                                <> &nbsp; - &nbsp;</>
                              )}
                              {item.location == "undefined"
                                ? ""
                                : item.location}{" "}
                              &nbsp; - &nbsp;
                              <span>
                                {item.date} {t("At")}{" "}
                                {item.time
                                  ? formatTime(item.time)
                                  : t("UNKNOWN")}
                              </span>
                            </p>

                            {item.video_thumb_url && (
                              <div>
                                <ReactPlayer
                                  url={item.attachment_url}
                                  controls
                                  className={styles.reactPlayerAdd}
                                />
                              </div>
                            )}
                            {item.attachment_thumb && (
                              <div className={styles.picShow}>
                                <LightGallery
                                  speed={500}
                                  closable={true}
                                  hideBarsDelay={0}
                                  controls={false}
                                  download={false}
                                  counter={false}
                                >
                                  <a href={item.attachment_url}>
                                    <LazyLoadImageComponent
                                      src={item.attachment_url}
                                      width="100"
                                      style={{ cursor: "pointer" }}
                                      height="100"
                                      alt="report image"
                                    ></LazyLoadImageComponent>
                                  </a>
                                </LightGallery>
                              </div>
                            )}

                            <div
                              className={`${styles.reportShow} ${styles.reportShowss}`}
                            >
                              <p>
                                <img src={commentIcon} alt="comment icon" />
                                &nbsp;&nbsp;
                                {item.comment_count == ""
                                  ? 0
                                  : item.comment_count}
                              </p>
                              <p>
                                <img src={viewIcon} alt="view icon" />
                                &nbsp;&nbsp;
                                {item.view_count == "" ? 0 : item.view_count}
                              </p>
                              <p>
                                <img src={bookmarkIcon} alt="bookmark icon" />
                                &nbsp;&nbsp;
                                {item.bookmark_count == ""
                                  ? 0
                                  : item.bookmark_count}
                              </p>
                              <p>
                                <img src={trendIcon} alt="trend icon" />
                                &nbsp;&nbsp;
                                {item.trend_count == "" ? 0 : item.trend_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.reportSvg}>
                          <SlArrowRight />
                        </div>
                      </div>

                      <div className={styles.borderBottom}></div>
                    </div>
                  ))
                ) : (
                  <div className="records_Found">
                    <h2>{t("NO_REC_FOUND")}</h2>
                  </div>
                )}

                {showDataList?.length < totalReports ? (
                  <div className="loadMoreSection">
                    <button className="loadMoreBtn" onClick={loadMoreReports}>
                      {t("LOAD_MORE")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ProfileDetail;
