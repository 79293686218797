import { NavLink, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import {
  setReportExit,
  setReportExitUrl,
  userLogoutClear,
} from "../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import { useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Toster from "../../utils/Toaster";
import {
  MessageCount,
  GetAlertCount,
  updateUserData,
} from "../../firebase/FireBaseChat";
import { convertToNumber } from "../../utils/manageConstant";
import DeleteConfirm from "../DeleteConfirmModal/DeleteConfirm";

//---function for user sidebar---------
function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { userToken, userData, currentUser, pushNotificationData } =
    useSelector((state) => state.user);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const reportExitModal = useSelector((state) => state.user.reportExitModal);
  const [count, setCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [title, setTitle] = useState("");

  async function UpdateFirebaseUser(user, updObject) {
    await updateUserData(user.userID, updObject);
  }

  //----- function for manage user logout-----
  const logout = async () => {
    setLoader(true);
    await SublyApi.logout(userToken).then(async (response) => {
      const errormsg = response && response.data && response.data.message;
      setLoader(false);
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const updateObject = {
          online: "isOffline",
          firebase_token: "",
        };
        await UpdateFirebaseUser(userData, updateObject);
        dispatch(userLogoutClear());
        navigate("/");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  //----- function for manage user account delete-----
  const accountDelete = async () => {
    setLoader(true);
    await SublyApi.deleteAccount(userToken).then(async (response) => {
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      setLoader(false);
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        dispatch(userLogoutClear());
        navigate("/");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  const handleClick = (event, url) => {
    event.preventDefault();
    dispatch(setReportExitUrl(url));
    if (reportExitModal == true) {
      dispatch(setReportExit(true));
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    const unsubscribe = MessageCount(currentUser, setCount);

    // Return a cleanup function to detach the listener when component unmounts
    return () => {
      unsubscribe(); // Detach the listener
    };
  }, [currentUser, setCount]);

  const numberCount = convertToNumber(count);

  useEffect(() => {
    const unsubscribe = GetAlertCount(currentUser, setAlertCount);

    // Return a cleanup function to detach the listener when component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentUser, setAlertCount]);

  const alertCountNumber = convertToNumber(alertCount);

  return (
    <div>
      {loader && <Loader />}
      <div className="sideTopBox">
        <h6>{userData && userData.name}</h6>
        <span>@{userData && userData.username}</span>
      </div>
      <div className="sideBar">
        <h5 className="sideHeading">{t("CRIMESPOTTER")}</h5>
        <NavLink
          onClick={(e) => handleClick(e, "/dashboard")}
          to="/dashboard"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("DASHBOARD")}</p>
          </div>
        </NavLink>
        <NavLink to="/new-report" className="userActive">
          <div className="displayOption">
            <p>{t("NEW_REPORT")}</p>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/your-report")}
          to="/your-report"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("YOUR_REPORT")}</p>
            <span>
              {userData &&
                (userData.total_reports == "" ? 0 : userData.total_reports)}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/messages")}
          to="/messages"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("MESSAGE")}</p>
            {numberCount > 0 && <span>{numberCount}</span>}
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/comments")}
          to="/comments"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("COMMENTS")}</p>
            <span>
              {" "}
              {userData &&
                (userData.total_comments == "" ? 0 : userData.total_comments)}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/alerts")}
          to="/alerts"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("ALERTS")}</p>
            <span>
              {" "}
              {userData &&
                (userData.total_alerts == "" ? 0 : userData.total_alerts)}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/push-notification")}
          to="/push-notification"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("PUSH_NOTIFY")}</p>
            <span>
              {pushNotificationData &&
                (pushNotificationData.push_alert == 1 ? "ON" : "OFF")}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/email-notification")}
          to="/email-notification"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("EMAIL_NOTIFY")}</p>
            <span>
              {userData && (userData.email_alert == 1 ? "ON" : "OFF")}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/alert-location")}
          to="/alert-location"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("ALERT_LOCATION")}</p>
            <span>
              {userData &&
                (userData.alertlocation_count == ""
                  ? 0
                  : userData.alertlocation_count)}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/profile")}
          to="/profile"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("YOUR_PROFILE")}</p>
            <span>
              <CgProfile />
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/login-settings")}
          to="/login-settings"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("LOGIN_SETTING")}</p>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/followers")}
          to="/followers"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("FOLLOWERS")}</p>
            <span>
              {" "}
              {userData &&
                (userData.follower_count == "" ? 0 : userData.follower_count)}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/following")}
          to="/following"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("FOLLOWING")}</p>
            <span>
              {" "}
              {userData &&
                (userData.following_count == "" ? 0 : userData.following_count)}
            </span>
          </div>
        </NavLink>
        <NavLink to="#">
          <div className="displayOption">
            <p>{t("NETWORK")}</p>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/connect-twitter")}
          to="/connect-twitter"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("CONNECT_TWITTER")}</p>
            <span>
              {" "}
              {userData &&
                userData.twitter_profile_username &&
                userData.user_meta.twitter_profile_username && (
                  <>
                    @
                    {userData &&
                      userData.twitter_profile_username &&
                      userData.user_meta.twitter_profile_username}
                  </>
                )}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/help-center")}
          to="/help-center"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("HELP_CENTER")}</p>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/terms-conditions")}
          to="/terms-conditions"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("TERMS")} </p>
            <span>
              {" "}
              {userData &&
              userData.is_terms_accepted &&
              userData.is_terms_accepted === 1
                ? t("ACCEPTED")
                : ""}
            </span>
          </div>
        </NavLink>
        <NavLink
          onClick={(e) => handleClick(e, "/privacy-policy")}
          to="/privacy-policy"
          className="userActive"
        >
          <div className="displayOption">
            <p>{t("PRIVACY")}</p>
          </div>
        </NavLink>
        <NavLink
          className="userActivess"
          onClick={() => {
            setShowLogoutModal(true);
            setTitle(`${t("LOGOUTTITLE")}`);
          }}
        >
          <div className="displayOption">
            <p>{t("LOGOUT")}</p>
          </div>
        </NavLink>
        <NavLink
          className="userActivess"
          onClick={() => {
            setShowAlertModal(true);
            setTitle(`${t("DELETETITLE")}`);
          }}
        >
          <div className="displayOption">
            <p>{t("DELETE_ACCOUNT")}</p>
          </div>
        </NavLink>
      </div>

      <DeleteConfirm
        setShowAlertModal={setShowAlertModal}
        showAlertModal={showAlertModal}
        onDelete={accountDelete}
        title={title}
      />

      <DeleteConfirm
        setShowAlertModal={setShowLogoutModal}
        showAlertModal={showLogoutModal}
        onDelete={logout}
        title={title}
      />
    </div>
  );
}
export default Sidebar;
